@use "../../../colors" as *;
// .BgColor {
//   background-color: $sectextcolor;
//   opacity: 0.5;
// }

.activitiesListFont {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}
