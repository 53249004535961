@use "../../colors" as *;
@use "../../components/Button/website/authenticationBtn.scss" as *;

.navbarBg {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.navTextColor,
.nav-link {
  color: rgba(0, 0, 0, 0.55);
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  &:hover {
    // background-color: $btncolor;
    color: $sectextcolor !important;
  }
  &:focus {
    // background-color: $btncolor;
    color: $sectextcolor !important;
  }
}
.justifyNavItemsEnd {
  justify-content: end !important;
}
.navbar-light .navbar-toggler {
  border-color: transparent !important;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}
.displayFlex {
  display: block;
}
.navContainerWidth {
  width: 14rem !important;
  // background-color:  black;
}

//responsiveness
// @media only screen and (max-width: 760px) {
//   .justifyNavItemsCenterSm {
//     justify-content: center;
//   }
// }
