@use "../../../../colors" as *;

.InstructorMaterialBg {
  background: #ffffff;
  box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.2);
}
.InstructorMaterialNav {
  border: 1px solid #000000;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
.urlFontColor {
  color: $sectextcolor;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}
.navFontColor {
  color: $sectextcolor;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}
