@use "../../../../colors" as *;
@use "../../../../font" as *;

@mixin BtnStyle {
  background-color: $buttoncolor;
  color: $tertextcolor;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
}

.NextBtn {
  @include BtnStyle;
  width: 7.5rem;
}
.BackBtn {
  @include BtnStyle;
  width: 7.5rem;
}
.SuscribeBtn {
  @include BtnStyle;
  width: 8rem;
}
.SubmitBtn {
  @include BtnStyle;
  width: 7.5rem;
}

.RegisterNowBtn {
  @include BtnStyle;
  width: 9.5rem;
}
