.containerBg {
  background: linear-gradient(
    180deg,
    rgba(28, 150, 233, 0.1) 0%,
    rgba(28, 150, 233, 0) 100%
  );
}
.congratsBgColor {
  background: linear-gradient(
    180deg,
    rgba(28, 150, 233, 0.4) 0%,
    rgba(28, 150, 233, 0) 100%
  );
  border: 1px solid rgba(28, 150, 233, 0.6);
}
