@use "./colors" as *;
h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--white);
  font-family: "Raleway", sans-serif;
  color: $sectextcolor;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: $sectextcolor;
}

h3 {
  font-size: 1.5rem;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
  color: $sectextcolor;
}
h4 {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: $sectextcolor;
  color: $sectextcolor;
}

h5 {
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
  color: $sectextcolor;
}
h6 {
  font-weight: 600;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  color: $sectextcolor;
}

p {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}
