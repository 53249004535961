@use "../../components/hero/homeHero";
@use "../../colors";

.fontStyle {
  font-size: 1.2rem;
  font-weight: 300;
  // color: #75117a !important;
}
.physicalVirtual {
  background-color: #000;
  color: #fff;
  width: 10.5rem;
}
.virtual {
  background-color: #d88d2a;
  color: #fff;
  width: 8rem;
}
.promoParagraph {
  color: #ed8b09;
}
.price-button {
  background-color: white;
  color: #75117a;
  border: 1px solid #75117a;
  border-radius: 0.2rem; /* Adjust border radius as needed */
  // padding: 10px 20px;
  margin-right: 5px;

  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  box-shadow: none !important;

  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.price-button:hover {
  background-color: #75117a;
  color: white;
  border: 1px solid #75117a;
}

.price-button.active {
  background-color: #75117a;
  color: white;
  border: 1px solid #75117a;
}

@media (min-width: 1024px) {
  .d-flex-modified h6:not(:last-child)::after {
    content: "";
    display: inline-block;
    border-left: 1px solid black;
    height: 1.1em;
    margin: 0 15px;
  }
}
@media (min-width: 768px) {
  .d-lg-flex .promoParagraph:not(:last-child)::after,
  .d-md-flex .promoParagraph:not(:last-child)::after {
    content: "";
    display: inline-block;
    border-left: 1px solid black;
    height: 1.1em;
    margin: 0 15px;
  }
}
