.dashboardNavbarBg {
  background: linear-gradient(
    180deg,
    #bc4c92 0%,
    rgba(208, 93, 219, 0.973958) 0.01%,
    rgba(127, 61, 183, 0.95) 100%
  );
  height: 6rem;
}
.dashboardSideNavText {
  font-size: 1.25rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}
.sideNavSmallText {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}
// .SideNavText{

// }
