@use "../../../../colors" as *;
@use "../../../../font" as *;

@mixin BtnStyle {
  background-color: $buttoncolor;
  color: $tertextcolor;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
}
.GetAnInstructorBtn {
  @include BtnStyle;
  width: 12.5rem;
}
.LearnMoreBtn {
  @include BtnStyle;
  width: 8rem;
}
.JoinOurTeamBtn {
  @include BtnStyle;
  width: 9.2rem;
}

.LearnMoreBtn {
  color: $sectextcolor;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
}
