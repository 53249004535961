.paymentpackage {
  // border: 2px solid;
  border-radius: 50px;
  box-shadow: 5px;
  // border-color: #1ebeb8;
  // color: #75117a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  // backdrop-filter: blur(5px);
}
.CardWork {
  gap: 40px;
  justify-content: space-between;
}
.Cardpicture {
  border-top-left-radius: 47px;
  border-top-right-radius: 47px;
  border-bottom-color: #1ebeb8;
}
