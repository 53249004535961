@use "../../colors" as *;
.createAccountBgOne {
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(209, 93, 219, 0.14) -60.79deg,
    rgba(28, 150, 233, 0.06) 89.87deg,
    rgba(209, 93, 219, 0.14) 299.21deg,
    rgba(28, 150, 233, 0.06) 449.87deg
  );
}
.createAccountBgTwo {
  background: rgba(255, 231, 255, 0.3);
}
// .formFieldWidth {
//   width: 500px;
//   height: 55px;
// }

.placeHolderBorder {
  // border: 0px solid #000000;
  border-radius: 10px;
  height: 3.2rem;
}
.mutedTextFontColor {
  color: #6c757d;
  border: none;
  &:hover {
    color: $buttoncolor;
  }
  &:focus {
    color: $buttoncolor;
  }
}

.joinUsModalBg {
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(28, 150, 233, 0.1) -60.7deg,
    rgba(209, 93, 219, 0.1) 63.33deg,
    rgba(28, 150, 233, 0.1) 299.3deg,
    rgba(209, 93, 219, 0.1) 423.33deg
  );
}
