* {
  box-sizing: border-box;
  font-family: "Karla", Rubik;
}

body {
  margin: 0;
  padding: 0 !important;
  overflow: visible !important;
}

.modal-backdrop.show {
  opacity: 0 !important;
}

h1 {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--white);
  font-family: "Raleway", sans-serif;
  color: #75117a;
}

h2 {
  font-size: 2rem;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: #75117a;
}

h3 {
  font-size: 1.5rem;
  font-weight: 900;
  font-family: "Raleway", sans-serif;
  color: #75117a;
}

h4 {
  font-size: 1.3rem;
  font-weight: 600;
  font-family: "Raleway", sans-serif;
  color: #75117a;
  color: #75117a;
}

h5 {
  font-weight: 600;
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
  color: #75117a;
}

h6 {
  font-weight: 600;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  color: #75117a;
}

p {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}

.NextBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 7.5rem;
}

.BackBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 7.5rem;
}

.SuscribeBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 8rem;
}

.SubmitBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 7.5rem;
}

.RegisterNowBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 9.5rem;
}

.teamCard {
  border-radius: 10px;
}

.LoginBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 8rem;
}
.LoginBtn:hover {
  background-color: #75117a;
  color: #ffffff;
}
.LoginBtn:focus {
  background-color: #75117a;
  color: #ffffff;
}

.CreateAccountBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 11rem;
}
.CreateAccountBtn:hover {
  background-color: #75117a;
  color: #ffffff;
}
.CreateAccountBtn:focus {
  background-color: #75117a;
  color: #ffffff;
}

.BackBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 7rem;
}
.BackBtn:hover {
  background-color: #75117a;
  color: #ffffff;
}
.BackBtn:focus {
  background-color: #75117a;
  color: #ffffff;
}

.joinUsTextColor {
  background: linear-gradient(180deg, #0f035e 76.04%, #1077f0 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-size: 2rem;
}

.navbarBg {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.navTextColor,
.nav-link {
  color: rgba(0, 0, 0, 0.55);
  font-weight: 600;
  font-family: "Raleway", sans-serif;
}
.navTextColor:hover,
.nav-link:hover {
  color: #75117a !important;
}
.navTextColor:focus,
.nav-link:focus {
  color: #75117a !important;
}

.justifyNavItemsEnd {
  justify-content: end !important;
}

.navbar-light .navbar-toggler {
  border-color: transparent !important;
}

.btn:focus {
  outline: none;
  box-shadow: none;
}

.displayFlex {
  display: block;
}

.navContainerWidth {
  width: 14rem !important;
}

.createAccountBgOne {
  background: conic-gradient(from 180deg at 50% 50%, rgba(209, 93, 219, 0.14) -60.79deg, rgba(28, 150, 233, 0.06) 89.87deg, rgba(209, 93, 219, 0.14) 299.21deg, rgba(28, 150, 233, 0.06) 449.87deg);
}

.createAccountBgTwo {
  background: rgba(255, 231, 255, 0.3);
}

.placeHolderBorder {
  border-radius: 10px;
  height: 3.2rem;
}

.mutedTextFontColor {
  color: #6c757d;
  border: none;
}
.mutedTextFontColor:hover {
  color: #75117a;
}
.mutedTextFontColor:focus {
  color: #75117a;
}

.joinUsModalBg {
  background: conic-gradient(from 180deg at 50% 50%, rgba(28, 150, 233, 0.1) -60.7deg, rgba(209, 93, 219, 0.1) 63.33deg, rgba(28, 150, 233, 0.1) 299.3deg, rgba(209, 93, 219, 0.1) 423.33deg);
}

.activitiesListFont {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}

.GetAnInstructorBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 12.5rem;
}

.LearnMoreBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 8rem;
}

.JoinOurTeamBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 9.2rem;
}

.LearnMoreBtn {
  color: #75117a;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
}

.homeHeroBgg::before {
  content: "";
  position: absolute;
  background-color: #2e3133;
  height: 65vh;
  width: 100%;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0;
}

.homeHeroBg {
  background: url(/src/assets/images/hero/Group\ 879.png);
  background-size: cover;
  position: relative;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  height: 65vh;
  width: 100%;
}

.homeHeroBg h2 {
  position: relative;
  line-height: 45px;
}

.homeHeroBg h3 {
  position: relative;
  line-height: 35px;
}

.homeHeroBg,
.GetAnInstructorBtn {
  position: relative;
}

.dd {
  display: flex;
  align-items: center !important;
}

@media only screen and (max-width: 523px) {
  .homeHeroBg {
    height: 27.5rem;
  }
}
@media (min-width: 524px) and (max-width: 754px) {
  .homeHeroBg {
    height: 37.5rem;
  }
}
@media (min-width: 755px) and (max-width: 767px) {
  .homeHeroBg {
    height: 40.5rem;
  }
}
@media (min-width: 767px) and (max-width: 780px) {
  .homeHeroBg {
    height: 28.5rem;
  }
}
.fontStyle {
  font-size: 1.2rem;
  font-weight: 300;
}

.physicalVirtual {
  background-color: #000;
  color: #fff;
  width: 10.5rem;
}

.virtual {
  background-color: #d88d2a;
  color: #fff;
  width: 8rem;
}

.promoParagraph {
  color: #ed8b09;
}

.price-button {
  background-color: white;
  color: #75117a;
  border: 1px solid #75117a;
  border-radius: 0.2rem; /* Adjust border radius as needed */
  margin-right: 5px;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  box-shadow: none !important;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s; /* Smooth transition for hover effect */
}

.price-button:hover {
  background-color: #75117a;
  color: white;
  border: 1px solid #75117a;
}

.price-button.active {
  background-color: #75117a;
  color: white;
  border: 1px solid #75117a;
}

@media (min-width: 1024px) {
  .d-flex-modified h6:not(:last-child)::after {
    content: "";
    display: inline-block;
    border-left: 1px solid black;
    height: 1.1em;
    margin: 0 15px;
  }
}
@media (min-width: 768px) {
  .d-lg-flex .promoParagraph:not(:last-child)::after,
.d-md-flex .promoParagraph:not(:last-child)::after {
    content: "";
    display: inline-block;
    border-left: 1px solid black;
    height: 1.1em;
    margin: 0 15px;
  }
}
.aboutHeroBg {
  background: conic-gradient(from 180deg at 50% 50%, rgba(209, 93, 219, 0.14) -60.79deg, rgba(28, 150, 233, 0.06) 89.87deg, rgba(209, 93, 219, 0.14) 299.21deg, rgba(28, 150, 233, 0.06) 449.87deg);
  border-radius: 0px 0px 200px 0px;
}

.bgColor {
  background: conic-gradient(from 180deg at 50% 50%, rgba(28, 150, 233, 0.7) -25.05deg, rgba(208, 93, 219, 0.7) 37.8deg, rgba(28, 150, 233, 0.7) 334.95deg, rgba(208, 93, 219, 0.7) 397.8deg);
}

@media only screen and (max-width: 575px) {
  .smallerWidth {
    width: 40%;
    height: 40%;
  }
  .smallWidth {
    width: 50%;
    height: 50%;
  }
}
@media (min-width: 576px) and (max-width: 991px) {
  .smallerWidth {
    width: 50%;
    height: 50%;
  }
  .smallWidth {
    width: 50%;
    height: 50%;
  }
}
.galleryBg {
  background: url(/src/assets/images/hero/gallerybg.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.galleryCard {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 4px 4px 10px 5px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  height: 10rem;
}

.galleryBtn:hover {
  background-color: #75117a;
  color: #ffffff;
}
.galleryBtn:focus {
  background-color: #75117a;
  color: #ffffff;
}

.blogImage {
  border-radius: 50px;
}

.blogcard {
  height: auto;
  border-style: hidden;
  color: #75117a;
}

.blogCategoryText {
  font-weight: 300;
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
  color: #75117a;
}

.btn-primary {
  --bs-btn-border-color: none !important;
}

.footerOneBgColor {
  background: linear-gradient(180deg, #ffffff 0%, rgba(201, 217, 224, 0.49) 66.15%, rgba(236, 236, 236, 0.516) 99.99%, #ffffff 100%);
}

.footerTwoBgColor {
  background: linear-gradient(180deg, #75117a 0%, #d15ddb 100%);
}

.footerParagraph {
  font-size: 1rem;
  font-weight: 300;
  color: #75117a;
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}

.footR {
  border-radius: 20px;
  display: flex;
  flex-direction: row;
  padding-top: 2rem;
  padding-left: auto;
  padding-right: auto;
  padding-bottom: 2rem;
  gap: 1rem;
  justify-content: center;
  justify-content: space-evenly;
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
  -webkit-padding-end: 20px;
          padding-inline-end: 20px;
  justify-content: center;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .footR {
    display: flex;
    flex-direction: column;
    margin: auto;
    justify-content: center;
    align-items: center;
    -webkit-padding-start: 20px;
            padding-inline-start: 20px;
  }
  .phone {
    padding: 1rem;
  }
}
ul {
  list-style: none;
}

a {
  text-align: center;
}

.ReadMoreBtn {
  border: 1px solid #75117a;
  background-color: #ffffff;
  border-radius: 10px;
  width: 8rem;
  height: 2.5rem;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
}
.ReadMoreBtn:hover {
  background-color: #75117a;
  color: #ffffff;
}
.ReadMoreBtn:focus {
  background-color: #75117a;
  color: #ffffff;
}

.GoHome {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  font-weight: 600;
  border: none;
  width: 15rem;
  border-radius: 10px;
}

.SaveBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  font-weight: 600;
  border: none;
  width: 8rem;
}

.CancelBtn {
  border: 1px solid #75117a;
  background-color: #ffffff;
  border-radius: 10px;
  width: 7.5rem;
  height: 2.5rem;
  font-family: "Raleway";
  font-style: normal;
  font-weight: 500;
}
.CancelBtn:hover {
  background-color: #75117a;
  color: #ffffff;
}
.CancelBtn:focus {
  background-color: #75117a;
  color: #ffffff;
}

.StartaNewSessionBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  font-weight: 600;
  border: none;
  width: 12rem;
}

.UpdateBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  font-weight: 600;
  border: none;
  width: 8rem;
}

.schoolPlaceHolderBorder {
  border-radius: 0px;
  height: 3.2rem;
}

.champFormBgColor {
  background: #dddddd;
}

.authPlaceHolderBorder {
  border-radius: 0px;
  height: 3.2rem;
  background-color: #dddddd;
}

.instructorCardBorder {
  border: 2px solid rgba(139, 41, 164, 0.94);
  box-shadow: 4px 0px 4px rgba(0, 0, 0, 0.5);
}

.TakeATestBtn {
  background-color: #ffffff;
  color: #75117a;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: 1px solid #75117a;
  width: 8rem;
}
.TakeATestBtn:hover {
  background-color: #75117a;
  color: #ffffff;
}

.SaveAndContinueBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 12rem;
}

.backToActivity {
  width: 15rem;
  background: #c4c4c4;
  height: 2.7rem;
  font-size: 1.3rem;
  font-family: "Raleway", sans-serif;
  font-style: bold;
  font-weight: 600;
  color: #ffffff;
  border-radius: 10px;
}

.undo {
  width: 15rem;
  background: #75117a;
  height: 2.7rem;
  font-size: 1.3rem;
  font-family: "Raleway", sans-serif;
  font-style: bold;
  font-weight: 600;
  color: #ffffff;
  border-color: #75117a;
  border-radius: 10px;
  word-spacing: 2px;
}

.undo:hover {
  background: #75117a;
  color: #ffffff;
}

.add {
  width: 13rem;
  background: #75117a;
  height: 2.7rem;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  border-color: #75117a;
  border-radius: 10px;
  word-spacing: 2px;
}

.add:hover {
  background-color: #75117a;
  color: #ffffff;
}

.addBtn {
  width: 6rem;
  background: #75117a;
  height: 2.7rem;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  border-color: #75117a;
  border-radius: 10px;
  word-spacing: 2px;
}

.UploadBestPhoto {
  width: 15rem;
  background: #75117a;
  height: 2.7rem;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  border-color: #75117a;
  border-radius: 10px;
  word-spacing: 2px;
}

.UploadBestPhoto:hover {
  background-color: #75117a;
  color: #ffffff;
}

.ChangePhoto {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
}

.BackToApplication {
  width: 15rem;
  background: #75117a;
  height: 2.7rem;
  font-size: 1 rem;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  border-color: #75117a;
  border-radius: 10px;
  word-spacing: 2px;
}

.BackToApplication:hover {
  background-color: #75117a;
  color: #ffffff;
}

.StartUpload {
  width: 15rem;
  background: #75117a;
  height: 2.7rem;
  font-size: 1 rem;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  border-color: #75117a;
  border-radius: 10px;
  word-spacing: 2px;
}

.StartUpload:hover {
  background-color: #75117a;
  color: #ffffff;
}

.Abort {
  width: 6rem;
  height: 2.7rem;
  font-size: 1rem;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 800;
  color: #75117a;
  border-color: #75117a;
  border-radius: 10px;
  word-spacing: 2px;
}

.Abort:hover {
  color: #75117a;
  border-radius: 2px;
}

.Done {
  width: 6rem;
  background: #75117a;
  height: 2.7rem;
  font-size: -2rem;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  font-weight: 600;
  color: #ffffff;
  border-color: #75117a;
  border-radius: 10px;
  word-spacing: 2px;
}

.Done:hover {
  background-color: #75117a;
  color: #ffffff;
}

.IAgreeBtn {
  background-color: #75117a;
  color: #ffffff;
  font-size: 15px;
  font-family: "Raleway", sans-serif;
  font-style: normal;
  border-radius: 10px;
  height: 2.7rem;
  width: 10.5rem;
  font-weight: 600;
  border: none;
  width: 8rem;
}

.addYourActivitiesBg {
  background: #dfeaef;
  border: 1px solid #bc4c92;
}

.selectTestBg {
  border: 1px solid rgba(208, 93, 219, 0.973958);
}

.selectTestBgOrange {
  background: linear-gradient(180deg, rgba(232, 128, 25, 0.58) 0%, rgba(232, 128, 25, 0.3712) 100%);
  border-radius: 20px;
}

.selectTestBgBorderPurple {
  border: 1px solid #75117a;
}

.profileUploadBgColor {
  background: linear-gradient(180deg, #724598 0%, rgba(209, 93, 219, 0.7) 54.69%);
  border-radius: 20px;
}

.IdUploadBgColor {
  background: #c6bbd1;
}

.VideoUploadBgOne {
  background: #ffffff;
  border: 1px solid #1c96e9;
}

.VideoUploadBgTwo {
  background: #dcf4ff;
  border: 1px solid #d15ddb;
}

.containerBg {
  background: linear-gradient(180deg, rgba(28, 150, 233, 0.1) 0%, rgba(28, 150, 233, 0) 100%);
}

.congratsBgColor {
  background: linear-gradient(180deg, rgba(28, 150, 233, 0.4) 0%, rgba(28, 150, 233, 0) 100%);
  border: 1px solid rgba(28, 150, 233, 0.6);
}

.InstructorMaterialBg {
  background: #ffffff;
  box-shadow: 0px 5px 20px 3px rgba(0, 0, 0, 0.2);
}

.InstructorMaterialNav {
  border: 1px solid #000000;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.urlFontColor {
  color: #75117a;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}

.navFontColor {
  color: #75117a;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}

.profileFormBorder {
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.titleTextColor {
  color: #75117a;
}

.cardBgColor {
  background-color: #75117a;
}

.paymentpackage {
  border-radius: 50px;
  box-shadow: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.CardWork {
  gap: 40px;
  justify-content: space-between;
}

.Cardpicture {
  border-top-left-radius: 47px;
  border-top-right-radius: 47px;
  border-bottom-color: #1ebeb8;
}

.dashboardNavbarBg {
  background: linear-gradient(180deg, #bc4c92 0%, rgba(208, 93, 219, 0.973958) 0.01%, rgba(127, 61, 183, 0.95) 100%);
  height: 6rem;
}

.dashboardSideNavText {
  font-size: 1.25rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}

.sideNavSmallText {
  font-size: 1rem;
  font-weight: 300;
  color: var(--white);
  line-height: 1.75rem;
  font-family: "Raleway", sans-serif;
}

.offcanvasBg {
  background-color: linear-gradient(180deg, #64348d 0%, rgb(209, 93, 219) 54.69%, rgba(139, 41, 164, 0.94) 100%);
}

.SideNavItem {
  color: black;
  font-weight: 700;
  font-size: 1.2rem;
  font-family: "Raleway", sans-serif;
}

.sideNavButton {
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 14rem;
  height: 2.5rem;
}
.sideNavButton:hover {
  color: #64348d;
  background-color: white;
}
.sideNavButton:focus {
  background-color: white;
  color: #64348d;
}

.submitFile {
  margin-left: 200px;
}

.virtualContentbg {
  box-sizing: border-box;
  margin-left: 3rem;
  margin-top: 3rem;
  position: absolute;
  width: 1241px;
  height: 500px;
  background: #ffffff;
  box-shadow: 0px 9px 50px 3px rgba(0, 0, 0, 0.2);
  padding: auto;
}

@media only screen and (max-width: 760px) {
  .virtualContentbg {
    width: 300px;
    height: 1000px;
  }
}
.timeStamp {
  border-radius: 0px 0px 10px 10px;
  background-color: linear-gradient(157.2deg, #F3FBFD 29.23%, rgba(201, 246, 255, 0.509434) 50.61%, rgba(63, 167, 188, 0) 79.86%);
}

.error404Bg {
  background-image: linear-gradient(180deg, rgba(137, 200, 232, 0.5) 0%, rgba(232, 128, 25, 0.185) 97.5%);
}

.paragraphColor {
  color: black;
}

.AdmintTutorNav {
  border: 1px solid #000000;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}
/*# sourceMappingURL=styles.css.map */