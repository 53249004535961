$prytextcolor: #000000;
$sectextcolor: #75117a;
$tertextcolor: #ffffff;
$buttoncolor: #75117a;
$prybgcolor: #ffffff;
$dashboardseccolor: linear-gradient(
  180deg,
  #64348d 0%,
  rgb(209, 93, 219) 54.69%,
  rgba(139, 41, 164, 0.94) 100%
);
