.aboutHeroBg {
  background: conic-gradient(
    from 180deg at 50% 50%,
    rgba(209, 93, 219, 0.14) -60.79deg,
    rgba(28, 150, 233, 0.06) 89.87deg,
    rgba(209, 93, 219, 0.14) 299.21deg,
    rgba(28, 150, 233, 0.06) 449.87deg
  );
  border-radius: 0px 0px 200px 0px;
}
