// .CalendarClass{
//      box-sizing: border-box;
//         margin-left: 30rem;
//         margin-top: 3rem;
//         position: absolute;
//         width: 500px;
//         height: 1000px;

// }.

// .calendarClass {
//   box-sizing: border-box;
//   margin-left: 15rem;
//   position: absolute;
//   margin-top: 3rem;
//   width: 500px;
//   height: 270px;
//   box-shadow: 0px 9px 50px 3px rgba(0, 0, 0, 0.2);
//   background: #ffffff;
//   padding: 15px;
// }
.timeStamp {
  border-radius: 0px 0px 10px 10px;
  //   background-color: #c9f6ff82;
  background-color:linear-gradient(157.2deg, #F3FBFD 29.23%, rgba(201, 246, 255, 0.509434) 50.61%, rgba(63, 167, 188, 0) 79.86%);;
}
